import revive_payload_client_g7mo90d5dV from "/app/node_modules/.pnpm/nuxt@3.15.4_@types+node@22.13.1_ioredis@5.5.0_sass-embedded@1.83.0_supports-color@9.4.0_tsx@4_o6j4b5euae7xrz3v6kpqk2fzuu/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_7VufVvpDfz from "/app/node_modules/.pnpm/nuxt@3.15.4_@types+node@22.13.1_ioredis@5.5.0_sass-embedded@1.83.0_supports-color@9.4.0_tsx@4_o6j4b5euae7xrz3v6kpqk2fzuu/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_ZOS7AKNikX from "/app/node_modules/.pnpm/nuxt@3.15.4_@types+node@22.13.1_ioredis@5.5.0_sass-embedded@1.83.0_supports-color@9.4.0_tsx@4_o6j4b5euae7xrz3v6kpqk2fzuu/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import supabase_client_AC0y6M9SvN from "/app/node_modules/.pnpm/@nuxtjs+supabase@1.4.6/node_modules/@nuxtjs/supabase/dist/runtime/plugins/supabase.client.js";
import payload_client_jibFKTs1fb from "/app/node_modules/.pnpm/nuxt@3.15.4_@types+node@22.13.1_ioredis@5.5.0_sass-embedded@1.83.0_supports-color@9.4.0_tsx@4_o6j4b5euae7xrz3v6kpqk2fzuu/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_uZDFSxJa2q from "/app/node_modules/.pnpm/nuxt@3.15.4_@types+node@22.13.1_ioredis@5.5.0_sass-embedded@1.83.0_supports-color@9.4.0_tsx@4_o6j4b5euae7xrz3v6kpqk2fzuu/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_i8lFLBu5v7 from "/app/node_modules/.pnpm/nuxt@3.15.4_@types+node@22.13.1_ioredis@5.5.0_sass-embedded@1.83.0_supports-color@9.4.0_tsx@4_o6j4b5euae7xrz3v6kpqk2fzuu/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_52ufw5mRaP from "/app/node_modules/.pnpm/nuxt@3.15.4_@types+node@22.13.1_ioredis@5.5.0_sass-embedded@1.83.0_supports-color@9.4.0_tsx@4_o6j4b5euae7xrz3v6kpqk2fzuu/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/app/apps/web/.nuxt/components.plugin.mjs";
import prefetch_client_PNjGhifbxV from "/app/node_modules/.pnpm/nuxt@3.15.4_@types+node@22.13.1_ioredis@5.5.0_sass-embedded@1.83.0_supports-color@9.4.0_tsx@4_o6j4b5euae7xrz3v6kpqk2fzuu/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_ziYcKwnJdq from "/app/node_modules/.pnpm/@formkit+auto-animate@0.8.2/node_modules/@formkit/auto-animate/nuxt/runtime/plugin.mjs";
import auth_redirect_Qn7t5ZsAmt from "/app/node_modules/.pnpm/@nuxtjs+supabase@1.4.6/node_modules/@nuxtjs/supabase/dist/runtime/plugins/auth-redirect.js";
import posthog_client_fUucxKWhZE from "/app/apps/web/plugins/posthog.client.ts";
import sentry_client_shVUlIjFLk from "/app/apps/web/plugins/sentry.client.ts";
export default [
  revive_payload_client_g7mo90d5dV,
  unhead_7VufVvpDfz,
  router_ZOS7AKNikX,
  supabase_client_AC0y6M9SvN,
  payload_client_jibFKTs1fb,
  navigation_repaint_client_uZDFSxJa2q,
  check_outdated_build_client_i8lFLBu5v7,
  chunk_reload_client_52ufw5mRaP,
  components_plugin_KR1HBZs4kY,
  prefetch_client_PNjGhifbxV,
  plugin_ziYcKwnJdq,
  auth_redirect_Qn7t5ZsAmt,
  posthog_client_fUucxKWhZE,
  sentry_client_shVUlIjFLk
]