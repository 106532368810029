import routerOptions0 from "/app/node_modules/.pnpm/nuxt@3.15.4_@types+node@22.13.1_ioredis@5.5.0_sass-embedded@1.83.0_supports-color@9.4.0_tsx@4_o6j4b5euae7xrz3v6kpqk2fzuu/node_modules/nuxt/dist/pages/runtime/router.options.js";
import routerOptions1 from "/app/apps/web/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export const hashMode = routerOptions0.hashMode ?? false
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}