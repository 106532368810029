<script setup lang="ts">
defineOptions({
  name: 'AppVue',
})

useHead({
  titleTemplate: t => (t ? `${t} - Ejaad` : 'Ejaad'),
})

const { state } = useUserState()

console.log('deployment 23-01-2025 11:44AM')
</script>

<template>
  <!-- <div class="fixed inset-0 text-center flex items-center justify-center h-dvh">
    Hey! This is Hamza. I'm running some tests on the server, which might take a while. Please check back later.
  </div> -->
  <NuxtLayout>
    <!-- <div class="fixed z-10 bottom-1 right-5 bg-gray-800 text-white text-xs rounded-md">
      <pre>
        {{ state }}
      </pre>
    </div> -->
    <NuxtPage />
  </NuxtLayout>
</template>

<style lang="scss" scoped></style>
